<template>
  <div>
    cargando...
  </div>
</template>

<script>
import { useRouter } from '@/utils'

export default {
  // _, context
  setup() {
    const { route, router } = useRouter()

    // const { $store } = context.root
    const { code, provider, providerId } = route.value.query

    const validarParametros = () => {
      if (code) {
        // Guardar en el store
        const userData = {
          code,
          provider,
          providerId,
        }

        localStorage.setItem('code', code)
        localStorage.setItem('providerId', providerId)

        // $store.commit('auth/saveUserData', { userData })

        // Redireccionar a OTP
        router.replace({ name: 'otp', params: userData })
      } else {
        router.replace({ name: 'error-404' })
      }
    }

    validarParametros()
  },
}
</script>

<style>

</style>
